import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  Fragment,
  useReducer,
  createRef,
} from "react";
import PropTypes, { node } from "prop-types";
import Layout from "../components/Layout";
import Work from "../components/Work";
import Timeline from "../components/Timeline";
import { graphql } from "gatsby";
import { groupBy, head } from "lodash";
import Store from "../components/store";
import reducers from "../components/reducers";
const WorksPageTemplate = ({ works, location }) => {
  const [openImage, setOpenImage] = useState(null);
  const ref = useRef([]);
  const [state, dispatch] = React.useReducer(
    reducers.reducer,
    reducers.initialState
  );

  const providerState = {
    state,
    dispatch,
  };
  const setSelectedYear = (year) => dispatch({ type: "set_year", data: year });

  useEffect(() => {
    let timer;
    if (state.selectedYear) {
      timer = setTimeout(() => {
        setSelectedYear(null);
      }, 500);
      const element = document?.getElementById(state.selectedYear);
      element?.scrollIntoView({ behavior: "smooth" });
    }
    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  const years = useMemo(
    () => groupBy(works, (w) => new Date(w.date).getFullYear()),
    [works]
  );

  const yearLeaders = useMemo(() => {
    const keys = Object.keys(years);
    return keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: head(years[key]).id,
      }),
      {}
    );
  }, []);

  ref.current = Object.keys(years).map(
    (year, i) => ref.current[i] ?? createRef()
  );

  const sortedYears = useMemo(
    () => Object.keys(years).sort((a, b) => Number(b) - Number(a)),
    [works]
  );

  useEffect(() => {
    if (sortedYears.length) {
      sessionStorage.setItem("years", JSON.stringify(sortedYears));
    }
  }, [sortedYears.length]);

  return (
    <Store.Provider value={providerState}>
      <Layout
        location={location.pathname}
        sortedYears={sortedYears}
        onMouseLeave={() => setOpenImage(null)}
      >
        <div className={`grid grid-cols-20 ${openImage ? "blur-md z-20" : ""}`}>
          <div className="col-span-1 md:grid hidden">
            <Timeline
              onSelectYear={setSelectedYear}
              works={works}
              sortedYears={sortedYears}
            />
          </div>
          <div className="md:col-span-16 col-span-full">
            {works.map((work, i) => (
              <Fragment key={i}>
                {yearLeaders[new Date(work.date).getFullYear()] === work.id && (
                  <div
                    ref={ref.current[i]}
                    className="invisible"
                    id={String(new Date(work.date).getFullYear())}
                  />
                )}
                <Work key={i} onClick={setOpenImage} work={work} />
                {i < works.length - 1 && (
                  <div className="border border-color-gray-800 w-full mb-10 rounded border-" />
                )}
              </Fragment>
            ))}
          </div>
        </div>
        {openImage?.image && (
          <div
            className={`fixed w-screen h-screen bg-blend-normal overflow-auto justify-center items-center flex off-screen
          ${openImage?.image ? "slide-in" : ""}`}
            onClick={() => setOpenImage(null)}
            style={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 99,
              padding: 50,
              overflow: "hidden",
            }}
          >
            <img
              srcSet={
                openImage.image.childImageSharp.gatsbyImageData.images
                  .sources[0].srcSet
              }
              style={{ maxHeight: "90vh", borderRadius: 5 }}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}
      </Layout>
    </Store.Provider>
  );
};

WorksPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const WorksPage = ({ data, location }) => {
  const { edges } = data.allMarkdownRemark;

  return (
    <WorksPageTemplate
      location={location}
      works={edges.map((edge) => ({
        id: edge.node.id,
        ...edge.node.frontmatter,
      }))}
    />
  );
};

WorksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default WorksPage;

export const pageQuery = graphql`
  query WorksPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "works-page" } }) {
      frontmatter {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "work-entry" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            images {
              image {
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: CONSTRAINED)
                }
              }
              description
            }
            description
          }
        }
      }
    }
  }
`;
