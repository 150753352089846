import * as React from "react";
import { graphql, withPrefix, useStaticQuery } from "gatsby";
import { groupBy } from "lodash";

function Timeline({ works, sortedYears, onSelectYear }) {
  const [hover, setHover] = React.useState<string>("");

  return (
    <div className="grid grid-cols-12 fixed z-10">
      <div className="col-span-full">
        {sortedYears.map((year, i) => (
          <div className="mb-6 text-xs cursor-pointer" key={i} onClick={() => onSelectYear(year)}>
            {year.slice(2)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Timeline;
