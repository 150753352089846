import * as React from "react";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

function Work({ work, onClick }) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <section className="container mb-10">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div
          className={`md:hidden col-span-1 ${
            expanded ? "h-auto" : "h-60 blur-text"
          }`}
        >
          {work.description}
        </div>
        <div className="flex pt-5 pb-10 col-span-1 md:hidden">
          <div
            className="button mt-2 border border-gray-800 justify-self-center rounded py-1 px-2 text-gray-600 hover:bg-gray-100 cursor-pointer mx-auto"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "⌃" : "read more"}
          </div>
        </div>
        <div className="">
          {work.images?.map((img, i) => (
            <div
              className={`${
                i < work.images.length-1 ? "mb-5" : ""
              } col-span-1 grid grid-cols-2 md:grid-cols-12`}
              key={i}
            >
              <div className={`col-span-8`}>
                <PreviewCompatibleImage
                  styles={{cursor:'pointer'}}
                  onClick={() => onClick(img)}
                  imageInfo={img}
                />
              </div>
              <div className={`px-5 text-sm col-span-4`}>{img.description}</div>
            </div>
          ))}
        </div>
        <div className={`hidden md:block relative h-auto`}>
          <div
            className={`text-sm text-justify pl-20 overflow-hidden ${
              expanded ? "h-auto" : "h-60 blur-text"
            }`}
          >
            {work.description}
          </div>
          <div className="flex pl-20">
            <div
              className="button mt-2 border border-gray-800 justify-self-center rounded py-1 px-2 text-gray-600 hover:bg-gray-100 cursor-pointer mx-auto"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "⌃" : "read more"}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Work;
